import Slider from "react-slick";
import Banner from "./components/Banner";
import Breadcrumbs from "./components/Breadcrumbs";
import ContactUs from "./components/ContactUs";
import Footer from "./components/Footer";
import Header from "./components/Header";
import ServiceBlock from "./components/ServiceBlock";
import Stats from "./components/Stats";
import Benefits from "./components/services/Benefits";
import EligibilityCheck from "./components/services/EligibilityCheck";
import Hero from "./components/services/Hero";
import HowItWorks from "./components/services/HowItWorks";
import KeyFeatures from "./components/services/KeyFeatures";
import testimonial from "./data/testimonial";
import Testimonial from "./components/Testimonial";
import faq from "./data/faq";
import Accordian from "./components/Accordian";
import downloadLink from "./lib/Download";

function AboutUs() {

  const teams = [
    {
      name: "Vinay Babu",
      position: "Founder & CEO",
      linkedin: "https://in.linkedin.com/in/vinay-babu-56a2a52a",
      image: "team/Vinaybabu.jpg"
    },
    {
      name: "Saravanakumar",
      position: "Co-Founder",
      linkedin: "https://in.linkedin.com/in/saravanadev",
      image: "team/Saravana.jpg"
    },
  ]
  const crumbs = [
    {
      link: '/',
      label: 'Home'
    },
    {
      link: '/aboutus',
      label: 'aboutus'
    },

  ]
  const testimonialSettings = {
    dots: false,
    autoplay: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 3,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };
  const banner = {
    title: "Your Trusted Partner in Comprehensive Insurance Solutions",
    subtitle: "We specialize in offering tailored insurance solutions to institutions and corporations, and our founding team has over 20 years of experience.",
    ctaLabel: "Connect with us",
    ctaLink: downloadLink(),
    image: "/assets/img/banner.png",
    smallTitle: "ABOUT BUCKSS INSURANCE"
  }

  return (
    <div>
      <Header />
      <Banner banner={banner} />
      <ServiceBlock
        title="Who We Are"
        subtitle="A narrative about how Buckss was founded, the inspiration behind the company, and its journey so far."
        points={[]}
        ctaLabel=""
        ctaLink=""
        image="/assets/img/whoarewe.png"
        type="primary"
        imageLocation="left"
      />
      <ServiceBlock
        title="Our Mission"
        subtitle="To empower individuals and businesses with tailored insurance solutions that provide security and peace of mind."
        points={[]}
        ctaLabel=""
        ctaLink=""
        image="/assets/img/mission.png"
        type="light"
        imageLocation="right"
      />
      <ServiceBlock
        title="Our Vision"
        subtitle="To be the leading insurance brokerage firm known for trust, innovation, and customer-centric services."
        points={[]}
        ctaLabel=""
        ctaLink=""
        image="/assets/img/vision.png"
        type="primary"
        imageLocation="left"
      />
      <ServiceBlock
        title="Solving MSME Insurance Needs"
        subtitle="Buckss Insurance understands the unique challenges faced by MSMEs in India. From limited awareness to mis-selling and trust issues, we are here to provide tailored insurance solutions that build a robust insurance infrastructure from scratch."
        points={[]}
        ctaLabel=""
        ctaLink=""
        image="/assets/img/msme.png"
        type="light"
        imageLocation="right"
      />
      <ServiceBlock
        title="Addressing Insurance Penetration Problems"
        subtitle="Despite the growing awareness, insurance penetration remains low in India. At Buckss, we are committed to bridging this gap by educating our clients about the importance of insurance and providing easy access to suitable policies."
        points={[]}
        ctaLabel=""
        ctaLink=""
        image="/assets/img/penetration.png"
        type="primary"
        imageLocation="left"
      />
      <ServiceBlock
        title="Mis-Selling and Building Trust Section"
        subtitle=""
        points={[
          "Mis-selling of insurance products is a significant issue that erodes trust. ",
          "Buckss Insurance is dedicated to transparency and honesty, ensuring our clients receive only the most suitable products for their needs. ",
          "Through personalized service, expert advice, and a commitment to our clients' well-being, we strive to build and maintain lasting relationships"
        ]}
        ctaLabel=""
        ctaLink=""
        image="/assets/img/msme.png"
        type="light"
        imageLocation="right"
      />
      <div className="my-3">
        <p className="text-center service-title">Our Clients</p>

        <div className="my-5">
          <div className="d-flex flex-row justify-content-center">
            <div className="mx-3">
              <img src="/assets/img/excelsoft.png" className="client-logo" />
            </div>
            <div className="mx-3">
              <img src="/assets/img/rangsons.png" className="client-logo" />
            </div>
            <div className="mx-3">
              <img src="/assets/img/nrr.png" className="client-logo" />
            </div>
          </div>
          <div className="d-flex flex-row justify-content-center flex-wrap my-3">
            <div className="mx-3 justify-content-center align-items-center d-flex">
              <img src="/assets/img/ExcelPublicSchool.png" className="client-logo-square" />
            </div>
            <div className="mx-3 justify-content-center align-items-center d-flex">
              <img src="/assets/img/WorldOfSilver.png" className="client-logo-square" />
            </div>
            <div className="mx-3 justify-content-center align-items-center d-flex">
              <img src="/assets/img/MadanJewellers.png" className="client-logo-square" />
            </div>
            <div className="mx-3 justify-content-center align-items-center d-flex">
              <img src="/assets/img/Seshadripuram.png" className="client-logo-square" />
            </div>
          </div>
        </div>
      </div>
      <div className="my-3 primaryBox  px-lg-5 px-lg-80 p-5">
        <p className="text-center meet-team">Meet Our Team</p>
        <p className="text-center team-title">Meet the dedicated professionals behind Buckss Insurance. Our team brings a wealth of experience and expertise to help you navigate the complexities of insurance.</p>

        <div className="my-5">
          <div className="d-flex flex-row justify-content-center flex-wrap">
            <div className="mx-3 d-flex flex-column justify-content-center align-items-center ">
              <img src="/assets/img/hareesh.png" className="client-logo" />
              <div className="d-flex flex-column justify-content-center align-items-center">
                <p className="text-center">Hareesh S</p>
                <p>National Sales Head</p>
                <div className="slide-cta outline-button-white ml-2">
                  <a className="nav-link" href="tel:9886546291"  >{"Contact Hareesh"}</a>
                </div>

              </div>
            </div>
            <div className="mx-3 d-flex flex-column justify-content-center align-items-center">
              <img src="/assets/img/tiwari.png" className="client-logo" />
              <div className="d-flex flex-column justify-content-center align-items-center">
                <p className="text-center">Vinod R Tiwari</p>
                <p>Principal Officer & Chief Business Officer</p>
                <div className="slide-cta outline-button-white ml-2">
                  <a className="nav-link" href="tel:9342182134"  >{"Contact Vinod R Tiwari"}</a>
                </div>
              </div>
            </div>
            <div className="mx-3 d-flex flex-column justify-content-center align-items-center">
              <img src="/assets/img/krishna.png" className="client-logo" />
              <div className="d-flex flex-column justify-content-center align-items-center">
                <p className="text-center">Krishna M.V</p>
                <p>Broker Qualified Person</p>
                <div className="slide-cta outline-button-white ml-2">
                  <a className="nav-link" href="tel:9902317933"  >{"Contact Krishna"}</a>
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>
      <div className="row banner mx-lg-5 mx-4 my-4">
        <div className="col-12 col-lg-7 px-lg-5 carousel-slide">
          <p className="banner-smallTitle ">PROTECT YOUR BUSINESS</p>
          <p className="service-title">Get a Quick Insurance Quote</p>
          <p className="service-subtitle">Comprehensive suite of insurance benefits and insurance products to protect your team and your business.</p>
          <div>
            <div className="nav-item primary-button my-3 mr-3">
              <a className="nav-link" href="mailto:po@buckss.in">Get a Quote</a>
            </div>
            <div className="nav-item outline-button my-3 ml-3">
              <a className="nav-link" href="mailto:po@buckss.in">Contact Us</a>
            </div>
          </div>
        </div>
        <div className="col-12 col-lg-5">
          <img className="slider-img" src="/assets/img/get-quote.png" />
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default AboutUs;
